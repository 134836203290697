<template>
  <section>
    <b-row class="">
      <b-col lg="6" sm="6">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" variant="light-primary" size="45">
              <feather-icon size="21" icon="UsersIcon" />
            </b-avatar>
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder">
                {{ data.male }}
              </h2>
              <span>الذكور المستفيدين</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="6" sm="6">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" variant="light-danger" size="45">
              <feather-icon size="21" icon="UsersIcon" />
            </b-avatar>
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder">
                {{ data.female }}
              </h2>
              <span>الإناث المستفيدين</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- <b-col
        lg="12"
        md="12"
      >
        <analytics-congratulation :data="data2.congratulations" />
      </b-col> -->
    </b-row>
    <b-row>
      <b-col>
        <statistics-age :data="data.persons_by_age" />
      </b-col>
    </b-row>

    <b-row class="">
      <b-col lg="12" md="12">
        <statistics-governate :data="data.persons_by_governorates" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col lg="6" md="6">
        <statistics-town :data="data.persons_by_towns" />
      </b-col>
      <b-col lg="6" md="6">
        <statistics-city :data="data.persons_by_cities" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col lg="6" md="6">
        <statistics-services :data="data.persons_per_main_service" />
      </b-col>
      <b-col lg="6" md="6">
        <statistics-sub-services :data="data.persons_per_service" />
      </b-col>
      <b-col lg="12">
        <analytics-sales-radar-chart />
      </b-col>
      <b-col lg="12" md="12">
        <statistics-weakness :data="data.persons_by_weakness" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import { BCard, BCardBody, BAvatar } from "bootstrap-vue";

// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import AnalyticsCongratulation from "./AnalyticsCongratulation.vue";
import StatisticsGovernate from "./statistics-governate.vue";
import StatisticsTown from "./statistics-town.vue";
import StatisticsAge from "./statistics-age.vue";
import StatisticsCity from "./statistics-city.vue";
import StatisticsServices from "./statistics-services.vue";
import StatisticsSubServices from "./statistics-sub-services.vue";
import AnalyticsSalesRadarChart from "./AnalyticsSalesRadarChart.vue";
import StatisticsWeakness from "./statistics-weakness.vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BAvatar,
    AnalyticsCongratulation,
    StatisticsGovernate,
    StatisticsCity,

    StatisticsTown,
    StatisticsServices,
    StatisticsSubServices,
    AnalyticsSalesRadarChart,
    StatisticsWeakness,
    StatisticsAge,
  },

  data() {
    return {
      data: {},
      data2: {},
      maleCount: 0,
      femaleCount: 0,
    };
  },

  created() {
    // data
    // this.$http.get("/analytics/data").then((response) => {
    //   this.data2 = response.data;
    // });
    this.$http.get("/api/v1/stats").then((response) => {
      //console.log("/api/v1/stats",response)
      this.data = response.data;
    });
  },
};
</script>

<style scoped>
.card-body.pb-0 {
  padding-bottom: 33px !important;
}
</style>
